import Cookies from 'js-cookie';
window.lightbox = require('lightbox2');
$(function () {
    /*
    * TopPage
    */
    if ($(".TopPage--MainImage").length) {
        $("#MainImage .Copy").delay(1000).fadeIn(1000);
    };
    if ($(".TopPage--Information").length) {
        $(document).on("click", ".Tab a", function () {
            $(".Tab a").removeClass('Active');
            $(this).addClass('Active');
            $(".News .List").removeClass('Show');
            $(".News .List").eq($('.Tab li a').index(this)).addClass('Show');

            return false;
            //$(this).next().slideToggle();
        });
    }
    /*
    * Banners
    */
    $('.Banners').owlCarousel({
        nav: false,
        dots: false,
        autoWidth: true,
        center: true,
        loop: true,
        margin: 20
    });
    $('.NextBtn').click(function () {
        $('.Banners').trigger('next.owl.carousel');
    })
    $('.PrevBtn').click(function () {
        console.log("a");

        $('.Banners').trigger('prev.owl.carousel');
    })
    /*
    * Header
    */
    $("#PageTitle").css({
        "margin-top": $("#Header").height() + 25
    })

    /*
    * FAQ
    */
    if ($(".FAQ--Box").length) {
        $(document).on("click", ".Question", function () {
            $(this).toggleClass('Active');
            $(this).next().slideToggle();
        });
    }
    /*
    * SP Nav
    */
    $(document).on("click", "#NavOpen", function () {
        $(".SP--Nav").addClass('Active');
    });

    $(document).on("click", "#NavClose", function () {
        $(".SP--Nav").removeClass('Active');
    });

    /*
    * スキルチェック
    */

    $(document).on("click", "#SkillCheck_Next", function () {
        let item = $(this);
        let data = {};
        $('.Question input[type="radio"]').each(function (i, elem) {
            if (elem.checked == true) {
                console.log(elem.value);
                data[elem.name] = elem.value;
            }
            if (i == $('.Question input[type="radio"]').length - 1) {
                if (Object.keys(data).length === $(".Question").length) {
                    Cookies.set(item.data("type"), data);
                    console.log(item.data("href"));
                    location.href = item.data("href");

                } else {
                    alert("設問に回答してください");
                }
            }
        });
        //
    });

    // Timer
    if ($("#TypingCheck").length) {
        let setSecond = 60; //タイマーの秒数
        let setPause = setSecond; //ストップ時の秒数を保存する変数　初期値はsetSecondと同じ数値
        let time = setSecond;   //残り秒数を保存する変数　初期値はsetSecondと同じ数値
        let timerID;    //setInterval用の変数

        //関数の設定-----------------------------------

        //残り秒数を表示させる関数
        function textDisplay() {
            $("#countDown").text(time);
        };

        //カウントを1減らす関数（setIntervalで毎秒実行される関数）
        function countDown() {
            time--;  //残り秒数を1減らす
            setPause = time;  //setPauseに残り秒数を代入（ストップ時に使用するため）
            textDisplay();    //1減った残り秒数を表示
        }

        //タイマー（setInterval）の停止用関数
        function countStop() {
            clearInterval(timerID); //（setInterval）をクリアー
        }

        //タイマースタートの関数
        function timerStart() {
            countStop();   //カウントダウンの重複を防ぐために今動いているタイマーをクリアーする ※1
            timerID = setInterval(function () {
                if (time <= 0) {
                    //残り秒数が0以下になったらタイマー（setInterval）をクリアー
                    clearInterval(timerID);
                    alert("タイミング終了です。");
                    //alert($("#Typing").val().length);
                    $("#Typing").attr("disabled", "disabled");
                    Cookies.set("typing_check", $("#Typing").val().length);
                } else {
                    //残り秒数が1以上あれば1減らす
                    countDown();
                }

            }, 1000);   //1000ミリ秒（1秒）毎にsetInterval内の処理を繰り返す
        };



        //実行処理-----------------------------------

        textDisplay();

        //スタートボタンクリックでタイマースタート
        $("#startBtn").click(function () {
            console.log("a");
            time = setPause; //setPauseに入っている秒数から開始
            textDisplay();
            timerStart();
        });
        //リセットボタンクリックでタイマー初期化
        $("#resetBtn").click(function () {
            countStop();
            time = setPause = setSecond; //setSecondに入っている秒数を代入し直す
            textDisplay();
        });

    }
})
